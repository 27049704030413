//general
$primary-color: #00ACB0;
$secondary-color: #007c7e;
$alert-color: #CA2432;
$text-color: #4c4c4c;
$title-color: #6F6F6F;
$border-color: #83D1D3;

$red-color: #CA2432;
$green-color: #007c7e;
$grey-color: #6F6F6F;

$blue-color: #3b468b;
$hover-color: #2e4073;

$size60: 52px;
$size48: 48px;
$size36: 36px;
$size32: 32px;
$size30: 30px;
$size24: 24px;
$size20: 20px;
$size18: 18px;
$size16: 16px;

//breakpoints
$breakpoint-desk: "only screen and (min-width: 1280px)";
$breakpoint-tablet-land: "screen and (min-width: 975px)";
$breakpoint-tablet: "only screen and (min-width: 600px)";
$breakpoint-mobile-land: "only screen and (min-width: 480px)";