.container-identify {
    display: flex;
}

.identify {
    background: #FFF;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    max-width: 740px;
    margin: 0 auto 50px;
    z-index: 9;

    p {
        color: #3b468b !important;
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    form {
        display: flex;
        label {
            display: flex;
            align-items: center;
            color: $blue-color;         
            font-size: $size18;
        }
        input {
            margin-left: 10px;
            height: 40px;
            border-radius: 15px;
            border: 1px solid $blue-color;
            padding: 0 10px;
            color: $blue-color;
            font-size: $size18;
            margin-right: 20px;
        }
        button {
            background-color: $blue-color;
            color: #FFF;
            font-weight: bold;
            border: none;
            height: 40px;
            width: 20%;
            border-radius: 15px;
            font-size: $size18;
        }
    }
}

@media screen and (max-width: 768px) {
    .identify {
        form {
            flex-direction: column;
            label {
                margin-bottom: 20px;
            }

            button {
                width: 100%;
            }
        }
    }
}