@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;700&display=swap');

* { 
    margin: 0; 
    padding: 0; 
    outline: 0; 
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
}

body {
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
}

.greenbg{
    background-color: $green-color;
}

.whitebg{
    background-color: #FFF;
}

.nonebg{
    background-color: #F1F1F1;
}
