.form {
    padding-bottom: 40px;

    .form-group {
        margin-bottom: 20px;
    }

    label {
        font-size: 18px;
        color: #FFF;
        width: 100%;
    }

    input, select {
        background-color: #153663;
        color: #FFF;
        font-size: 18px;
        padding: 8px;
        border: solid 2px #FFF;
        margin-top: 5px;
        width: 100%;
    }

    .btn-add-array {
        background-color: transparent;
        color: #FFF;
        border: none;
        padding: 10px 0;
        font-size: 16px;
    }

    .btn-input-delete {
        border: solid 2px #FFF !important;
        border-left: 0 !important;
        margin-top: 4px !important;
        height: 41px !important;
        width: 41px !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .input-array-group {
        display: flex;
        align-items: center;
        button {
            background-color: transparent;
            font-size: 24px;
            color: #FFF;
            border: none;
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    button[type=submit] {
        color: #153663;
        padding: 10px 50px;
        font-size: 24px;
    }
}