footer {
    padding: 0;
    background: #FFF;
    ul {
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 18px;
            a {
                color: $blue-color;
                font-size: $size20;
                &:hover {
                    color: $hover-color;
                }
            }
        }
    }
    #ul-b {
        li {
            a {
                font-weight: bold;
            }
            a#a-n {
                font-weight: 400;
            }
        }
    }
    .logo-flex {
        align-items: center;
        margin-bottom: 35px;

        img {
            width: 100%;
            max-width: 175px;
            margin-right: 40px;
        }

        p {
            color: #3b468b;
            font-size: 18px;
        }
    }
    .d-flex {
        margin-top: 50px;
        
        a {
            display: flex;
            align-items: center;
            color: $blue-color; 
            &:hover {
                opacity: .9;
            }
            img {
                width: 20px;
                margin-right: 10px;
            }
        } 
    }
    .d-flex-terms {
        align-items: center;
        margin-bottom: 30px;
        p {
            font-size: 35px;
            color: $blue-color;
            margin: 0 20px;
        }
    }
    .d-flex-mobile {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    footer {
        ul {
            margin-top: 25px;
        }
        .logo-flex {
            flex-direction: column;
            text-align: center;
            img {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
        .d-flex-terms {
            flex-direction: column;
            align-items: flex-start;
            p {
                display: none;
            }
            a {
                margin: 5px 0;
            }
        }
    }
}