.intro{
    display: flex;

    .intro-contents {
        width: 100%;
        margin-right: -50%;

        .intro-text {
            position: relative;
            width: calc(45%);

            .circle-dotted {
                position: absolute;
                bottom: -60%;
                right: -30%;
                border-radius: 100%;
                width: 220%;
                padding-bottom: 220%;
                border: dotted 5px #FFF;
            }
        }
    }
    
    img {
        z-index: 9;
        width: 50%;
        height: auto;
        object-fit: contain;
    }
}

@media screen and (max-width: 991px) {
    .intro{
        flex-direction: column;
    
        .intro-contents {
            width: 100%;
    
            .intro-text {
                width: 100%;
    
                .circle-dotted {
                    display: none;
                }
            }
        }
        
        img {
            display: none;
        }
    }
}