@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBMPlexSans'), url(../fonts/IBMPlexSans/IBMPlexSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    src: local('OpenSans'), url(../fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans Bold';
    src: local('OpenSans'), url(../fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}