#header { 
    position: relative;
    background-color: #FFF;
    z-index: 99;

    .btn-mobile {
        display: none;
        .row-btn-mobile {
            height: 4px;
            width: 35px;
            background: $blue-color;
            margin: 5px 0;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            #logo {
                width: 100%;
                max-width: 185px;
                margin-top: 10px;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        a {
            display: table;
            color: $text-color;
            font-size: 18px;
        }

        > ul {
            display: flex;

            > li {
                position: relative;

                > a {
                    padding: 35px 20px;
                    border-bottom: solid 1px #ccc;
                }

                > ul {
                    display: none;
                }
            }
        }
    }
    ul.main-mobile {
        flex-direction: column;
        position: absolute;
        top: 83px;
        left: 0;
        right: 0;
        height: 100vh;
        background: #FFF;
    }
}

@-webkit-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@media screen and (max-width:768px) {
    #header {
        padding: 10px 0;

        .btn-mobile {
            display: block;
        }

        .container {
            .main {
                display: none;
            }
            a {
                #logo {
                    max-width: 150px;
                }
            }

            > ul  {
                > li {
                    a {
                        width: 100%;
                    }
                    &:hover {
                        > ul {
                            position: relative;
                        }
                    }
                }
            }
        }
    } 
}