.faq {
    position: relative;
    padding-top: 1px;
    background: #203961;
    background: linear-gradient(90deg, #203961 0%, #484c94 100%);
    padding-bottom: 75px;

    h2 {
        font-family: 'League Spartan Bold', sans-serif;
        color: #FFF;
        font-size: $size30;
        margin-top: 35px;
        margin-bottom: 30px;
        text-decoration: underline;
    }

    .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 2px solid #FFF;
    }

    .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw svg {
        fill: #FFF;
    }

    .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
        color: #FFF;
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
        padding-right: 3em;
        font-weight: bold;
    }

    section.faq-question {
        position: relative;
        margin-bottom: 90px;
        img#shapes {
            position: absolute;
            bottom: -120px;
            left: -125px;
            width: 100%;
            max-width: 215px;
        }
    }

    a {
        color: #FFF;
        line-height: 26px;
    }

    p {
        color: #FFF;
        line-height: 26px;
        a {
            color: #FFF;
        }
    }

    b {
        color: #FFF;
    }
}

@media screen and (max-width: 768px) {
    .faq section.faq-question img#shapes {
        position: absolute;
        bottom: -105px;
        left: -15px;
        width: 100%;
        max-width: 132px;
    }
}